import React from "react"
import Shell from '../components/global/shell'
import Container from '../components/global/container'
import styled from 'styled-components'
import tw from "twin.macro"

const Wrapper = styled.div`
  p {
    ${tw`pb-3 md:pb-5 pt-2 text-sm md:text-xl md:leading-8`}
  }
`;

const Page = (props) => {

  return (
    <Shell className="">
      <Container>

        <div className="flex md:flex-row flex-wrap mt-12 md:mt-28">
          
          <div className="w-full md:w-1/3 pr-3">
            <h1 className="text-xl md:text-3xl font-bold pb-3 text-gray-900">{props.title}</h1>
          </div>

          <Wrapper className="w-full md:w-2/3 md:pl-5 mt-12 md:mt-0" 
           dangerouslySetInnerHTML={{ __html: props.text }} />

        </div>

      </Container>
    </Shell>
  )
}

export default Page

