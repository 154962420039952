import React from "react"
import { graphql } from 'gatsby'
import Page from '../components/page';

const About = (props) => {
  return (
    <>
      <Page title="Über diese Seite" text={props.data.page.html}/>
    </>
  ) 
}

export default About


export const pageQuery = graphql`
  query AboutQuery {
    page: markdownRemark(frontmatter: {content: {eq: "page"}, title: {eq: "about"}}) {
      id
      frontmatter {
        title
      }
      rawMarkdownBody
      html
    }
  }
`;